jQuery(document).ready(function ($) {
  $('.slider-wrap').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: '.slider-image-wrap',
  });
  $('.slider-image-wrap').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: '.slider-wrap',
    dots: true,
    focusOnSelect: true,
  });
});
