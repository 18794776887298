jQuery(document).ready(function ($) {
  if ($('.slider-top').length && $('.slider-bottom').length) {
    $('.slider-top').slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      initialSlide: 0,
      centerMode: true,
      centerPadding: '3%',
      asNavFor: '.slider-bottom',
      arrows: true,
      responsive: [
        {
          breakpoint: 1920,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            centerMode: true,
            centerPadding: '16%',
            asNavFor: '.slider-bottom',
            arrows: true,
          },
        },
        {
          breakpoint: 1553,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            centerMode: true,
            centerPadding: '5%',
          },
        },
        {
          breakpoint: 1140,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            centerMode: true,
            centerPadding: '30%',
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            centerMode: false,
            centerPadding: '0',
          },
        },
      ],
    });
    $('.slider-bottom').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      asNavFor: '.slider-top',
      arrows: false,
      fade: true,
    });
  }
});
