document.addEventListener('DOMContentLoaded', function () {
  var tabLinks = document.querySelectorAll('.tab-link');

  var tabContents = document.querySelectorAll('.tab-content');

  function activateTab(index) {
    tabLinks.forEach(function (link) {
      link.classList.remove('active');
    });
    tabContents.forEach(function (content) {
      content.classList.remove('active');
    });
    tabLinks[index].classList.add('active');
    tabContents[index].classList.add('active');
  }

  tabLinks.forEach(function (link, index) {
    link.addEventListener('click', function (event) {
      event.preventDefault();
      activateTab(index);
    });
  });
  if (tabLinks.length > 0) {
    activateTab(0);
  }
});
