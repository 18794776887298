/*global ajax_object*/

import $ from 'jquery';

$(document).ready(function () {
  var categoriesArray = [];

  $('.category-list-item, .clear-selection').hide();

  $('.category-list__item').on('click', function () {
    var selectedCategory = $(this).data('category');
    $(this).toggleClass('active-category');

    if ($(this).hasClass('active-category')) {
      if (!categoriesArray.includes(selectedCategory)) {
        categoriesArray.push(selectedCategory);
        $('.category-list-item').each(function () {
          if ($(this).data('category') == selectedCategory) {
            $(this).show();
          }
        });

        $.ajax({
          url: ajax_object.ajax_url,
          type: 'POST',
          data: {
            action: 'filter_winners',
            category: categoriesArray,
            paged: 1,
          },
          success: function (response) {
            $('.blog-loop-list').html(response);
          },
          error: function (errorThrown) {
            console.log(errorThrown);
          },
        });
      }
    }

    if (categoriesArray.length > 0) {
      $('.clear-selection').show();
    } else {
      $('.clear-selection').hide();
    }
  });

  $('.category-filter-clear').on('click', '.category-list-item', function () {
    var selectedCategory = $(this).data('category');
    categoriesArray = categoriesArray.filter(
      (category) => category !== selectedCategory
    );
    $(this).hide();

    $('.category-list__item').each(function () {
      if ($(this).data('category') == selectedCategory) {
        $(this).removeClass('active-category');
      }
    });

    $.ajax({
      url: ajax_object.ajax_url,
      type: 'POST',
      data: {
        action: 'filter_winners',
        category: categoriesArray,
        paged: 1,
      },
      success: function (response) {
        $('.blog-loop-list').html(response);
      },
      error: function (errorThrown) {
        console.log(errorThrown);
      },
    });

    if (categoriesArray.length === 0) {
      $('.clear-selection').hide();
    }
  });

  $('.clear-selection').on('click', function () {
    $('.category-list__item').removeClass('active-category');
    categoriesArray = [];
    $('.category-list-item').hide();
    $(this).hide();

    $.ajax({
      url: ajax_object.ajax_url,
      type: 'POST',
      data: {
        action: 'clear_filter_winners',
        paged: 1,
      },
      success: function (response) {
        $('.blog-loop-list').html(response);
      },
      error: function (errorThrown) {
        console.log(errorThrown);
      },
    });
  });
  $(document).on('click', '.js-more-button', function () {
    let page = $(this).data('paged');
    let currButton = $('.more-button-wrap');
    $.ajax({
      type: 'POST',
      url: ajax_object.ajax_url,
      data: {
        action: 'filter_winners',
        category: categoriesArray,
        paged: page,
      },
      beforeSend() {},
      success: function (response) {
        currButton.remove();
        $('.blog-loop-list').append(response);
        page++;
        $('.js-more-button').data('paged', page);
      },
    });
  });
});
