document.addEventListener('DOMContentLoaded', function () {
  const links = document.querySelectorAll('.accordion-link');

  links.forEach((link) => {
    link.addEventListener('click', function (event) {
      event.preventDefault();

      // Remove active class from the previously active link, content, and image
      const activeLink = document.querySelector('.accordion-link.active');
      if (activeLink) {
        activeLink.classList.remove('active');
      }

      const activeContent = document.querySelector('.accordion-content.active');
      if (activeContent) {
        activeContent.classList.remove('active');
      }

      const activeImage = document.querySelector(
        '.accordion-image-item.active'
      );
      if (activeImage) {
        activeImage.classList.remove('active');
      }

      // Add active class to clicked link and corresponding content and image
      this.classList.add('active');
      const index = this.getAttribute('data-index');
      document
        .querySelector(`.accordion-content[data-index='${index}']`)
        .classList.add('active');
      document
        .querySelector(`.accordion-image-item[data-index='${index}']`)

        .classList.add('active');
    });
  });
});
