// import $ from 'jquery';

jQuery(document).ready(function ($) {
  const isMobile = window.innerWidth <= 1200;

  $('.vertical-accordion-content.active').show();

  $('.vertical-accordion-link').click(function (e) {
    e.preventDefault();
  });

  $('.next-button').click(function (e) {
    e.stopPropagation();
    let currentIndex = $(this)
      .closest('.vertical-accordion-content')
      .data('index');
    let nextIndex = currentIndex + 1;
    let prevIndex = nextIndex - 1;
    if (nextIndex >= $('.vertical-accordion-item').length) {
      nextIndex = 0;
    }

    if (prevIndex < 0) {
      prevIndex = $('.vertical-accordion-item').length - 1;
    }

    navigateToSlide(nextIndex, prevIndex);
  });

  $('.prev-button').click(function (e) {
    e.stopPropagation();
    let currentIndex = $(this)
      .closest('.vertical-accordion-content')
      .data('index');
    let nextIndex = currentIndex - 1;
    let prevIndex = nextIndex + 1;

    if (nextIndex < 0) {
      nextIndex = $('.vertical-accordion-item').length - 1;
    }

    if (prevIndex >= $('.vertical-accordion-item').length) {
      prevIndex = 0;
    }

    navigateToSlide(nextIndex, prevIndex);
  });

  function navigateToSlide(nextIndex) {
    let nextContent = $(
      '.vertical-accordion-content[data-index="' + nextIndex + '"]'
    );

    $('.vertical-accordion-nav').removeClass('hidden');
    $('.vertical-accordion-content').removeClass('active');
    $('.vertical-accordion-content').removeClass('done');

    $('.vertical-accordion-link[data-index="' + nextIndex + '"]')
      .closest('.vertical-accordion-nav')
      .addClass('hidden');

    nextContent.addClass('active');
  }

  console.log(isMobile);

  if (isMobile) {
    $('.vertical-accordion-item').each(function (index, element) {
      $(element).on('click', function (e) {
        e.preventDefault();
        const content = $(element).find('.vertical-accordion-content');
        const nav = $(element).find('.vertical-accordion-nav');
        console.log(content, nav);

        $('.vertical-accordion-nav').removeClass('hidden');
        $('.vertical-accordion-content').removeClass('active');
        $('.vertical-accordion-content').removeClass('done');

        nav.addClass('hidden');
        content.addClass('active');
      });
    });
  }

  addBoxClickable();
});

function addBoxClickable() {
  $('.accordion-vertical .vertical-accordion-item').on('click', function () {
    if ($(this).find('.vertical-accordion-content').hasClass('active')) {
      return;
    } else {
      $(
        '.accordion-vertical .vertical-accordion-item .vertical-accordion-content'
      ).removeClass('active');
      $(
        '.accordion-vertical .vertical-accordion-item .vertical-accordion-nav'
      ).removeClass('hidden');

      $(this).find('.vertical-accordion-content').addClass('active');
      $(this).find('.vertical-accordion-nav').addClass('hidden');
    }
  });
}
