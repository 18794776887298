// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line
// Import local dependencies
/* global FWP */
/* global ajax_object */
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import 'jquery-ui/ui/widgets/selectmenu';
import JSZip from 'jszip';

import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';
/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';
/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
import '../blocks/elementor/accordion-image/index.js'; import '../blocks/elementor/blog-loop-grid/index.js'; import '../blocks/elementor/double-slider/index.js'; import '../blocks/elementor/example-widget/index.js'; import '../blocks/elementor/industry-loop/index.js'; import '../blocks/elementor/industry-parent/index.js'; import '../blocks/elementor/loop-image-cat/index.js'; import '../blocks/elementor/tabs/index.js'; import '../blocks/elementor/vertical-accordion/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  // $('a[download]').on('click', function (e) {
  //   e.preventDefault();
  //   const url = $(this).attr('href');
  //   const fileName = url.match(/[^/]+$/)[0];
  //   downloadFile(url, fileName);
  // });
  //
  // const downloadFile = (url, filename) => {
  //   fetch(url)
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       const link = document.createElement('a');
  //       const url = window.URL.createObjectURL(blob);
  //       link.href = url;
  //       link.setAttribute('download', filename);
  //       document.body.appendChild(link);
  //       link.click();
  //       link.remove();
  //       window.URL.revokeObjectURL(url);
  //     })
  //     .catch((error) => console.error('Download failed:', error));
  // };

  // Check if the button exists
  if ($('.product-assets-download').length) {
    // Attach the click event listener
    $('.product-assets-download').on('click', function () {
      // Get the file links from the ACF field (in this case, from a hidden div)
      const acfField = $('#acf-file-links').text().trim();

      // Split the file links by commas to create an array
      const fileLinks = acfField.split(',');

      // Create a new instance of JSZip
      let zip = new JSZip();

      // Add files to the ZIP archive
      let promises = fileLinks.map((link, index) => {
        const trimmedLink = link
          .trim()
          .replace(/–/g, '-') // Replace en dashes with hyphens
          .replace(/ /g, '%20'); // Replace spaces with %20

        return fetch(trimmedLink)
          .then((response) => {
            if (!response.ok) {
              throw new Error(
                `Failed to fetch ${trimmedLink}: ${response.statusText}`
              );
            }
            return response.blob();
          })
          .then((blob) => {
            const extension = blob.type.split('/')[1] || 'bin';
            zip.file(`file${index + 1}.${extension}`, blob);
          });
      });

      // When all files are added, generate the ZIP and trigger the download
      Promise.all(promises)
        .then(() => {
          zip.generateAsync({ type: 'blob' }).then(function (content) {
            // Create a temporary download link
            let downloadLink = $('<a></a>')
              .attr('href', URL.createObjectURL(content))
              .attr('download', 'files.zip') // Name of the downloaded ZIP file
              .appendTo('body'); // Append the link to the body
            downloadLink[0].click(); // Trigger the download
            downloadLink.remove(); // Clean up
          });
        })
        .catch((error) => {
          console.error('Error fetching files:', error);
        });
    });
  }

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();
  $('.cat-img-loop .blog-post').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  // const selectWrapper = document.querySelector('.ginput_container_select');
  // const selectElement = document.querySelector('.large.gfield_select');
  //
  // selectElement.addEventListener('focus', () => {
  //   selectWrapper.classList.add('opened');
  // });
  //
  // selectElement.addEventListener('blur', () => {
  //   selectWrapper.classList.remove('opened');
  // });
  $(function () {
    $(' .ginput_container_select select').selectmenu();
    $(' #industry-filter').selectmenu();
  });
  // $(function () {
  //   $('.elementor-popup-modal .ginput_container_select select').selectmenu();
  // });

  /*
   *  Rotate wheel
   * */

  let rotation = 0;

  $('#wheel-next').on('click', function (event) {
    event.preventDefault();
    rotation -= 90;
    $('.wheel-image .attachment-large').css(
      'transform',
      `rotate(${rotation}deg)`
    );
  });

  $('#wheel-prev').on('click', function () {
    event.preventDefault();
    rotation += 90;
    $('.wheel-image .attachment-large').css(
      'transform',
      `rotate(${rotation}deg)`
    );
  });

  const menuToggle = document.querySelector('.elementor-menu-toggle');
  const body = document.body;
  menuToggle.addEventListener('click', (e) => {
    e.stopPropagation();
    if (!menuToggle.classList.contains('elementor-active')) {
      body.classList.add('menu-open');
    } else {
      body.classList.remove('menu-open');
    }
  });
  document.addEventListener('click', (e) => {
    const isMenuToggle = e.target.closest('.elementor-menu-toggle');
    const isNavMenu = e.target.closest('.elementor-nav-menu');
    if (isMenuToggle || isNavMenu) {
      // body.classList.remove('menu-open');
    }
    // if (!body.classList.contains('menu-open')) {
    //   body.classList.add('menu-open');
    // } else {
    //   body.classList.remove('menu-open');
    // }
  });

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
  deleteProductTaxLink();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});
/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here
  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
  document.addEventListener('scroll', function () {
    var header = document.querySelector('.header-container');
    if (window.scrollY > 0) {
      header.classList.add('scroll');
    } else {
      header.classList.remove('scroll');
    }
  });
});

document.addEventListener('DOMContentLoaded', function () {
  const inputFields = document.querySelectorAll('.gform_wrapper .gfield input');

  inputFields.forEach((inputField) => {
    const wrapper = inputField.closest('.gfield');

    inputField.addEventListener('focus', function () {
      wrapper.classList.add('gfield--is-active');
    });

    inputField.addEventListener('blur', function () {
      if (this.value.trim() === '') {
        wrapper.classList.remove('gfield--is-active');
      }
    });
  });

  const selectFields = document.querySelectorAll(
    '.gform_wrapper .gfield select'
  );

  selectFields.forEach((selectField) => {
    const wrapper = selectField.closest('.gfield');

    selectField.addEventListener('focus', function () {
      wrapper.classList.add('gfield--is-active');
    });

    selectField.addEventListener('blur', function () {
      wrapper.classList.remove('gfield--is-active');
    });

    selectField.addEventListener('change', function () {
      wrapper.classList.add('gfield--is-active-permanently');
    });
  });
});

// const selectFields = document.querySelectorAll('.gform_wrapper .gfield select');
//
// selectFields.forEach((selectField) => {
//   const firstOption = selectField.options[0];
//   const optionValue = firstOption.textContent;
//   firstOption.innerHTML = `${optionValue} <span class='required-asterisk'>*</span>`;
// });

$(document).ready(function () {
  let removedSlides = false;

  function initializeSlick() {
    const $sliderContainer = $(
      '.home-loop-slider .elementor-loop-container.elementor-grid'
    );

    if ($(window).width() <= 1023) {
      if (!$sliderContainer.hasClass('slick-initialized')) {
        $sliderContainer.on('init', function (event, slick) {
          $sliderContainer.addClass('slick-initialized');
          if (!removedSlides) {
            slick.slickRemove(0); // Remove the first slide on Slick initialization
            removedSlides = true;
          }
        });

        $sliderContainer.slick({
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          arrows: true,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        });
      }
    }

    if (
      $(window).width() >= 1024 &&
      $sliderContainer.hasClass('slick-initialized')
    ) {
      $sliderContainer.removeClass('slick-initialized');
      $sliderContainer.slick('unslick'); // Unslick the slider
    }
  }

  function debounce(func, wait) {
    let timeout;
    return function () {
      clearTimeout(timeout);
      timeout = setTimeout(func, wait);
    };
  }

  const debouncedInitializeSlick = debounce(initializeSlick, 200);

  initializeSlick();
  $(window).resize(function () {
    debouncedInitializeSlick();
  });
});

$(document).on('elementor/popup/show', () => {
  $('.elementor-popup-modal .ginput_container_select select').selectmenu();
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  const inputFields = document.querySelectorAll('.gform_wrapper .gfield input');

  inputFields.forEach((inputField) => {
    const wrapper = inputField.closest('.gfield');

    inputField.addEventListener('focus', function () {
      wrapper.classList.add('gfield--is-active');
    });

    inputField.addEventListener('blur', function () {
      if (this.value.trim() === '') {
        wrapper.classList.remove('gfield--is-active');
      }
    });
  });

  const selectFields = document.querySelectorAll(
    '.gform_wrapper .gfield select'
  );

  selectFields.forEach((selectField) => {
    const wrapper = selectField.closest('.gfield');

    selectField.addEventListener('focus', function () {
      wrapper.classList.add('gfield--is-active');
    });

    selectField.addEventListener('blur', function () {
      wrapper.classList.remove('gfield--is-active');
    });

    selectField.addEventListener('change', function () {
      wrapper.classList.add('gfield--is-active-permanently');
    });
  });
});

document
  .querySelectorAll('.facetwp-per-page-buttons button')
  .forEach((button) => {
    button.addEventListener('click', function () {
      // Remove the 'active' class from all buttons
      document
        .querySelectorAll('.facetwp-per-page-buttons button')
        .forEach((btn) => {
          btn.classList.remove('active');
        });

      // Add the 'active' class to the clicked button
      this.classList.add('active');

      // Get the value and trigger the per-page functionality (adjust this part for FacetWP integration)
      const value = this.getAttribute('data-value');

      // Assuming you're working with FacetWP, update the per-page facet like this:
      FWP.extras.per_page = value;
      FWP.refresh(); // Refresh the FacetWP results
    });
  });
if (window.location.href === 'https://dchemquest.wpengine.com/products/') {
  window.location.href =
    'https://dchemquest.wpengine.com/products/?_per_page=9';
}
jQuery(document).ready(function ($) {
  // Wait for the FacetWP search field to be rendered
  $(document).on('facetwp-loaded', function () {
    // Replace the icon with a button
    var searchWrap = $('.facetwp-input-wrap');
    var searchIcon = searchWrap.find('.facetwp-icon');

    if (searchIcon.length > 0) {
      // Remove the icon and insert a button
      searchIcon.replaceWith(
        '<button type="button" class="facetwp-submit search-button"><span>Search</span></button>'
      );
    }

    // Add a click event to the new button to trigger the FacetWP search
    $('.search-button').on('click', function () {
      FWP.refresh(); // Trigger FacetWP search refresh
    });
  });
});

// Delete links from product tax
function deleteProductTaxLink() {
  $('.product-tax-buttons a').removeAttr('href').css({
    cursor: 'default',
  });
}

// Add last page pagination button
$(document).on('facetwp-loaded', () => {
  addLastPagePaginationButton();
});

function addLastPagePaginationButton() {
  const pagedContainer = $('.facetwp-pager');

  const lastPageLink = $(pagedContainer).find('a.last');
  const firstPageLink = $(pagedContainer).find('a.first');

  const lastPageNumber = $(lastPageLink).data('page');
  const firstPageNumber = $(firstPageLink).data('page');

  const currentPageNumber = $(pagedContainer).find('a.active').data('page');

  if (currentPageNumber != lastPageNumber) {
    const svg =
      '<svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.2519 1.99996L7.02839 7.16826L1.77648 13.0382" stroke="white" stroke-width="3"/></svg>';

    const goToLast = $(`<a class='go-to-last'></a>`).append(svg).append(svg);

    $(goToLast).on('click', () => {
      FWP.paged = lastPageNumber;

      FWP.soft_refresh = true;

      FWP.refresh();
    });

    $(pagedContainer).append($(goToLast));
  }

  if (currentPageNumber != firstPageNumber) {
    const svg =
      '<svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.2519 1.99996L7.02839 7.16826L1.77648 13.0382" stroke="white" stroke-width="3"/></svg>';

    const goToFirst = $('<a class="go-to-first"></a>').append(svg).append(svg);

    $(goToFirst).on('click', () => {
      FWP.paged = firstPageNumber;

      FWP.soft_refresh = true;

      FWP.refresh();
    });

    $(pagedContainer).prepend($(goToFirst));
  }
}

document.addEventListener('DOMContentLoaded', function () {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('_industries')) {
    const industries = urlParams.getAll('_industries');
    if (industries.length > 0) {
      const facetData = { industries: industries };
      FWP.parse_facets(facetData);
      FWP.refresh();
    }
  }
});

jQuery(document).ready(function ($) {
  $('#industry-filter').selectmenu({
    change: function () {
      var selectedTerm = $(this).val();
      $('#loading-spinner').show();
      $.ajax({
        url: ajax_object.ajax_url,
        type: 'POST',
        data: {
          action: 'filter_industries',
          term: selectedTerm,
          nonce: ajax_object.nonce,
        },
        success: function (response) {
          $('#loading-spinner').hide();

          if (response.success) {
            $('.term-template').html(response.data);
          } else {
            $('.term-template').html('<p>No terms found</p>');
          }
        },
        error: function () {
          $('#loading-spinner').hide();

          $('.term-template').html('<p>An error occurred</p>');
        },
      });
    },
  });

  $('#industry-filter-button').on('click', function () {
    // $('#industry-filter').selectmenu('widget').toggle();
  });
});
function addSearchText() {
  if ($(window).width() >= 768) {
    $('.elementor-search-form__submit .e-font-icon-svg-container').each(
      function () {
        if (!$(this).next('.elementor-screen-only').length) {
          $(this).after('<span class="elementor-screen-only">Search</span>');
        }
      }
    );
  } else {
    $('.elementor-screen-only').remove();
  }
}

$(document).ready(addSearchText);
$(window).resize(addSearchText);
